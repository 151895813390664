
















































import { defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import UploadFile from "./UploadFile.vue";
import ListItem from "./ListItem.vue";
import api from "@/services";
export default defineComponent({
  components:{
    UploadFile,
    ListItem
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    loadingBtn: {
      type: Boolean,
      default: false,
    },
    handlerCancel: {
      type: Function,
    },
    handlerSubmit: {
      type: Function,
    },
  },
  setup: (props, ctx) => {
    let formData = ref<Record<string, any>>({});
    let formData2 = ref<Record<string,any>[]>([]);
    const changeFileUpload = (file:any)=>{
      console.log(file);
    }
    const btnCancelClick = () => {
      ctx.emit("handlerCancel");
    };
    const isActive = ref<boolean>(false)
    const btnSubmitClick = () => {
      if(!formData.value.bag_ids){
        isActive.value = true;
        return
      }
      ctx.emit("handlerSubmit", formData.value);
    };
    return {
      btnCancelClick,
      btnSubmitClick,
      changeFileUpload
    };
  },
  methods: {},
  watch: {
    
  }
});
