<template>
  <div class="form-todo-container">
    <div v-if="!listCurrentTodo.length">
      <p class="no-result-noti text-center fw-500 mt-3">
        Không có công việc liên quan
      </p>
    </div>
    <div class="header-group-btn d-flex justify-content-end py-3">
      <v-btn
        color="#005a88"
        class="btn-yes popup__btn mx-3"
        :loading="loadingBtn"
        :disabled="loadingBtn"
        @click="handlerOpenDialogAddJob"
      >
        Tạo mới</v-btn
      >
      <v-btn
        color="#005a88"
        class="btn-yes popup__btn"
        :loading="loadingBtn"
        :disabled="loadingBtn"
        @click="dialogSTodo = true"
      >
        Đình kèm công viêc đã tạo</v-btn
      >
    </div>
    <div class="list-current-todo">
      <div
        v-for="todo in listCurrentTodo"
        :key="todo.id"
        class="each-current-todo"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <img
              src="@/assets/images/red-close.png"
              alt=""
              class="pointer detach-img"
              v-bind="attrs"
              v-on="on"
              @click="onDetachTodo(todo.id)"
            />
          </template>
          <span>Xóa</span>
        </v-tooltip>
        <p
          class="todo-name text-limit-2 pointer"
          title="Xem chi tiết"
          @click="redirectTodo(todo.id)"
        >
          Mã số {{ todo.id }}: {{ todo.title }}
        </p>
        <p class="todo-user-do mb-0 line-label text-limit-3">
          Người thực hiện:
          <span>{{ todo.users_do ? nameUsersDo(todo.users_do) : "" }}</span>
        </p>
        <p class="todo-status mb-0 line-label">
          Trạng thái:
          <span
            :class="[`todo-status-${todo.status ? todo.status.name : 'fl'}`]"
            >{{ todo.status ? getStatusNameVN(todo.status.id) : "" }}</span
          >
        </p>
        <p class="todo-created-at mb-0 line-label">
          Ngày tạo: <span>{{ formatDMY(todo.created_at) }}</span>
        </p>
        <p class="todo-deadline mb-0 line-label">
          Thời hạn: <span>{{ formatDMY(todo.due_date) }}</span>
        </p>
      </div>
    </div>
    <v-dialog v-model="dialogSTodo" persistent max-width="700">
      <v-card>
        <v-card-text class="py-3">
          <div class="w-100 display-flex align-center justify-space-between">
            <span class="font-size-14 font-weight-bold text-blur-color">
              Đính kèm công việc liên quan
            </span>
            <img
              src="@/assets/images/small-close.png"
              alt=""
              class="pointer"
              @click="dialogSTodo = false"
            /></div
        ></v-card-text>
        <div class="px-5">
          <div class="field-attach-todo">
            <v-text-field
              placeholder="Tìm công việc"
              outlined
              dense
              clearable
              v-model="txtQuery"
              @click:clear="txtQuery = ''"
            ></v-text-field>
            <div class="list-todo-relate">
              <p
                v-if="
                  txtQuery &&
                  !listAvailableTodoComputed.length &&
                  listAvailableTodo.length
                "
                class="each-todo-label"
              >
                Không có kết quả tìm kiếm phù hợp.
              </p>
              <div
                v-for="tdo in listAvailableTodoComputed"
                :key="tdo.id"
                class="each-todo-relate d-flex align-items-center"
              >
                <input
                  v-model="listPickedTodo"
                  type="checkbox"
                  :id="`each-todo-${tdo.id}`"
                  :value="tdo.id"
                />
                <label
                  :for="`each-todo-${tdo.id}`"
                  class="each-todo-label pointer text-limit-w px-2"
                  :class="listPickedTodo.includes(tdo.id) ? 'active-label' : ''"
                >
                  {{ tdo.id }}: {{ tdo.title }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-add__ft">
          <v-btn
            color="#005a88"
            class="btn-yes btn-normal-case popup__btn"
            @click="onAttachTodo"
          >
            Lưu</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <DialogAddJob
      :is-visible="isVisible"
      @handlerCancel="handlerDialogCancel"
      @handlerSubmit="handlerDialogSubmit"
      :err-msg="errMsg"
      :loading-btn="loadingBtn"
    />
  </div>
</template>

<script>
import DialogAddJob from "./DialogAddJob.vue";
import { tStr } from "validation_t/src";
import api from "@/services";
export default {
  components: { DialogAddJob },
  props: {
    currentData: {
      type: Object,
    },
    getCurrentData: {
      type: Function,
    },
  },
  data() {
    return {
      isVisible: false,
      currentId: "",
      loadingBtn: false,
      errMsg: "",
      dialogSTodo: false,
      listCurrentTodo: [],
      listAvailableTodo: [],
      listPickedTodo: [],
      txtQuery: "",
    };
  },
  mounted() {
    const params = this.$route.params;
    this.currentId = params.id;
    this.getListTodoAvailable();
    if (this.currentData) {
      this.listCurrentTodo = this.currentData.tasks
        ? [...this.currentData.tasks]
        : [];
    }
  },
  computed: {
    listAvailableTodoComputed() {
      return this.listAvailableTodo.filter((o) => {
        const txtSrc = String(o.id + o.title).replaceAll(" ", "");
        return tStr
          .removeAscent(txtSrc)
          .includes(
            tStr.removeAscent(String(this.txtQuery || "").replaceAll(" ", ""))
          );
      });
    },
  },
  watch: {
    $route() {
      const params = this.$route.params;
      this.currentId = params.id;
    },
    currentData: {
      deep: true,
      handler() {
        if (this.currentData) {
          this.listCurrentTodo = this.currentData.tasks
            ? [...this.currentData.tasks]
            : [];
        }
      },
    },
    // listPickedTodo: {
    // 	deep: true,
    // 	handler() {
    // 		this.$nextTick(() => {
    // 			if (this.listPickedTodo && this.listPickedTodo.length > 1) {
    // 				this.listPickedTodo = [this.listPickedTodo.pop()];
    // 			}
    // 		});
    // 	},
    // },
  },
  methods: {
    handlerOpenDialogAddJob() {
      this.isVisible = true;
    },
    handlerDialogCancel() {
      this.isVisible = false;
    },
    formatDate(ds) {
      let ar1 = ds.split("-");
      return `${ar1[2]}-${ar1[1]}-${ar1[0]}`;
    },
    formatTick(ds) {
      return ds ? 1 : 0;
    },
    async getListTodoAvailable() {
      const res = await api.approval.getListTodoAvailable();
      if (!res) {
        return;
      }
      try {
        if (res.status > 199 && res.status < 399) {
          this.listAvailableTodo = res.data.data.tasks;
        } else {
          this.$store.commit("SET_SNACKBAR", {
            type: "error",
            title: "",
            content: res.data.message || res.data.data.error,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onAttachTodo() {
      if (!this.listPickedTodo || !this.listPickedTodo.length) {
        return;
      }
      // const body = {
      // 	task_ids: [...this.listPickedTodo],
      // };
      const formData = new FormData();
      this.listPickedTodo.forEach((o) => {
        formData.append("task_ids[]", o);
      });
      const res = await api.approval.attachTodo(this.currentId, formData);
      if (!res) {
        return;
      }
      try {
        if (res.status > 199 && res.status < 399) {
          this.getListTodoAvailable();
          await this.getCurrentData();
          this.$store.commit("SET_SNACKBAR", {
            type: "success",
            title: "",
            content: "Gán công việc thành công",
          });
          this.dialogSTodo = false;

          this.listPickedTodo = [];
        } else {
          this.$store.commit("SET_SNACKBAR", {
            type: "error",
            title: "",
            content: res.data.message || res.data.data.error,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onDetachTodo(_id) {
      const body = {
        task_id: _id,
      };
      const res = await api.approval.detachTodo(this.currentId, body);
      if (!res) {
        return;
      }
      try {
        if (res.status > 199 && res.status < 399) {
          this.listPickedTodo = [];
          this.getListTodoAvailable();
          await this.getCurrentData();
          this.$store.commit("SET_SNACKBAR", {
            type: "success",
            title: "",
            content: "Gỡ công việc thành công",
          });
          this.dialogSTodo = false;
        } else {
          this.$store.commit("SET_SNACKBAR", {
            type: "error",
            title: "",
            content: res.data.message || res.data.data.error,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handlerDialogSubmit(value, value2, value3, value4) {
      // let due_date = this.formatDate(value.due_date);
      let due_date =
        this.formatDate(value.due_date) + " " + value.due_dateTime + ":00";
      let important = this.formatTick(value.is_important);
      let arrayId = value2.map((o) => o.id);
      let arrayFollowersId = value4.map((o) => o.id);
      let arrayFile = value3.map((o) => o);
      // console.log(value);
      // if (value) {
      // 	return;
      // }
      delete value.due_date;
      delete value.is_important;
      const formData = new FormData();
      arrayFile.forEach((element) => {
        formData.append("attachments[]", element.blob);
      });
      arrayFollowersId.forEach((element) => {
        formData.append("user_follow_ids[]", element);
      });
      arrayId.forEach((element) => {
        formData.append("user_do_ids[]", element);
      });
      formData.append("due_date", due_date);
      formData.append("important", important);
      formData.append("title", value.title);
      formData.append("content", value.content);

      this.errMsg = "";
      this.loadingBtn = true;
      const res = await api.approval.addTask(this.currentId, formData);
      this.loadingBtn = false;
      if (!res) {
        return;
      }
      try {
        if (res.status > 199 && res.status < 399) {
          this.$store.commit("SET_SNACKBAR", {
            type: "success",
            title: "",
            content: "Tạo việc thành công",
          });
          this.$store.dispatch("tasks/getListTask");
          this.handlerDialogCancel();
          this.getCurrentData();
        } else {
          this.errMsg = res.data.message || res.data.data.error;
          this.$store.commit("SET_SNACKBAR", {
            type: "error",
            title: "",
            content: res.data.message || res.data.data.error,
          });
        }
      } catch (error) {
        console.log(error);
        this.$store.commit("SET_SNACKBAR", {
          type: "error",
          title: "",
          content: "Lỗi hệ thống, liên hệ IT để được hỗ trợ.",
        });
      }
    },
    formatDMY(ds) {
      let ar1 = ds.split(" ");
      let ar2 = ar1[0].split("-");
      return `${ar2[2]}/${ar2[1]}/${ar2[0]}`;
    },
    nameUsersDo(users) {
      let name = "";
      users.forEach((o, idx) => {
        name += idx > 0 ? `, ${o.full_name}` : o.full_name;
      });
      return name;
    },
    redirectTodo(_id) {
      const currentLocationOrigin = window.location.origin;
      window.open(`${currentLocationOrigin}/giao-viec/${_id}?type=own&size=15`);
    },
    getStatusNameVN(_sttCode) {
      switch (_sttCode) {
        case 1:
          return "Mới";
        case 2:
          return "Đang làm";
        case 3:
          return "Hoàn thành";
        case 4:
          return "Hủy";
        default:
          return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.list-current-todo {
  height: calc(100vh - 295px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    opacity: 0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.15);
    }
  }

  .each-current-todo {
    padding: 14px 16px;
    border-bottom: 1px solid #dfe5ea;
    position: relative;
    .detach-img {
      position: absolute;
      top: 20px;
      right: 16px;
      z-index: 2;
    }
    .todo-name {
      width: fit-content;
      text-align: left;
      color: #006a9b;
      font-weight: 500;
      text-decoration: underline;
    }
    .line-label {
      font-size: 14px;
      color: #223140;
      font-weight: 500;
      span {
        font-weight: 700;
      }
    }
    .todo-status {
      &-Doing {
        color: #006a9b;
      }
      &-New {
        color: #e6a326;
      }
      &-Finish {
        color: #21c99c;
      }
      &-Cancel {
        color: #b61f34;
      }
    }
  }
}
</style>
<style lang="scss">
.form-todo-container {
  .btn-yes {
    color: #ffffff !important;
    background: #005a88;
    font-size: 12px;
    height: 40px;
    border-radius: 8px;
    /* width: 98px; */
    text-transform: none;
  }
  .header-group-btn {
    border-bottom: 1px solid #dfe5ea;
  }
  .no-result-noti {
    color: #8194a5;
  }
}
.btn-normal-case {
  text-transform: none !important;
}
.btn-yes {
  color: #ffffff !important;
}
.dialog-add__ft {
  display: flex;
  justify-content: flex-end;
  padding: 12px 20px 12px 30px;
  margin: 0;
  background-color: #f2f4f6;
}
.field-attach-todo {
  .v-input__control {
    .v-input__slot {
      font-size: 14px !important;
      fieldset {
        color: rgb(227 239 223) !important;
      }
    }
  }
}
.list-todo-relate {
  height: 240px;
  overflow-y: scroll;
  padding: 12px;
  border: solid 1px #dee5eb;
  margin-bottom: 16px;
  &::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    opacity: 0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.15);
    }
  }
  .each-todo-relate {
  }
  .each-todo-label {
    font-size: 14px;
    color: #8194a5;
    &.active-label {
      font-weight: 500;
      color: #006a9b;
    }
  }
}
</style>
