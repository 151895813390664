
















import {
 reactive,
 ref,
 defineComponent,
 toRef,
 toRefs,
} from "@vue/composition-api";
import { mapState } from "vuex";
import store from "@/store";
export default defineComponent({
 props: {
  isVisible: {
   type: Boolean,
   default: false,
  },
  handlerCancel: {
   type: Function,
  },
  handlerConfirm: {
   type: Function,
  },
  selectedData: {
   type: Object,
  },
 },
 setup(props) {
  let handlerCancel: Record<string, any> = toRef(props, "handlerCancel");
  let handlerConfirm: Record<string, any> = toRef(props, "handlerConfirm");
  const cancel = () => {
   handlerCancel.value();
  };
  const confirm = () => {
   handlerConfirm.value();
  };
  return {
   cancel,
   confirm,
  };
 },
});
