<template>
	<div class="form-relate-container">
		<div class="list-parent-approval">
			<div v-if="parentComputed && parentComputed.length">
				<div v-for="parent in parentComputed" :key="parent.id" class="each-current-approval">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<img
								src="@/assets/images/red-close.png"
								alt=""
								class="pointer detach-img"
								v-bind="attrs"
								v-on="on"
								@click="onDetachParent(parent.id)"
							/>
						</template>
						<span>Xóa</span>
					</v-tooltip>
					<p class="todo-name text-limit-2 pointer" title="Xem chi tiết" @click="redirectTodo(parent.id)">
						Mã số {{ parent.id }}: {{ parent.name }}
					</p>
					<p class="todo-user-do mb-0 line-label text-limit-3">
						Người thực hiện: <span>{{ parent.users_do ? nameUsersDo(parent.users_do) : '' }}</span>
					</p>
					<p class="todo-status mb-0 line-label">
						Trạng thái:
						<span :class="[`todo-status-${parent.status ? getStatusClass(parent.status.id) : 'fl'}`]">{{
							parent.status ? parent.status.name : ''
						}}</span>
					</p>
					<p class="todo-created-at mb-0 line-label">
						Ngày tạo: <span>{{ formatDMY(parent.created_at) }}</span>
					</p>
				</div>
			</div>
			<div v-if="!parentComputed || !parentComputed.length">
				<p class="no-result-noti text-center fw-500 mt-3">Không có đề xuất liên quan</p>
			</div>
			<v-btn color="#005a88" class="btn-yes btn-normal-case popup__btn d-block mx-auto my-3" @click="showAddRelate(0)">
				Gán đề xuất gốc</v-btn
			>
		</div>
		<div class="list-child-approval">
			<div v-for="approval in listCurrentChildren" :key="approval.id" class="each-current-approval">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<img
							src="@/assets/images/red-close.png"
							alt=""
							class="pointer detach-img"
							v-bind="attrs"
							v-on="on"
							@click="onDetachChild(approval.id)"
						/>
					</template>
					<span>Xóa</span>
				</v-tooltip>
				<p class="todo-name text-limit-2 pointer" title="Xem chi tiết" @click="redirectTodo(approval.id)">
					Mã số {{ approval.id }}: {{ approval.name }}
				</p>
				<p class="todo-user-do mb-0 line-label text-limit-3">
					Người đề xuất: <span>{{ approval.users_do ? nameUsersDo(todo.users_do) : '' }}</span>
				</p>
				<p class="todo-status mb-0 line-label">
					Trạng thái:
					<span :class="[`todo-status-${approval.status ? getStatusClass(approval.status.id) : 'fl'}`]">{{
						approval.status ? approval.status.name : ''
					}}</span>
				</p>
				<p class="todo-created-at mb-0 line-label">
					Ngày tạo: <span>{{ formatDMY(approval.created_at) }}</span>
				</p>
			</div>
			<div v-if="!listCurrentChildren || !listCurrentChildren.length">
				<p class="no-result-noti text-center fw-500 mt-3">Không có đề xuất liên quan</p>
			</div>
			<v-btn color="#005a88" class="btn-yes btn-normal-case popup__btn d-block mx-auto mt-3" @click="showAddRelate(1)">
				Gán đề xuất liên quan</v-btn
			>
		</div>
		<v-dialog v-model="dialogSTodo" persistent max-width="700">
			<v-card>
				<v-card-text class="py-3">
					<div class="w-100 display-flex align-center justify-space-between">
						<span class="font-size-14 font-weight-bold text-blur-color"> Đính kèm đề xuất liên quan </span>
						<img src="@/assets/images/small-close.png" alt="" class="pointer" @click="onCloseAddRelate" /></div
				></v-card-text>
				<div class="px-5">
					<div class="field-attach-approval">
						<v-text-field
							placeholder="Tìm kiếm ..."
							outlined
							dense
							clearable
							v-model="txtQuery"
							@click:clear="txtQuery = ''"
						></v-text-field>
						<div class="list-approval-relate" @scroll="onScrollLoadmore">
							<p v-if="txtQuery && listToShown && !listToShown.length" class="each-approval-label">
								Không có kết quả tìm kiếm phù hợp.
							</p>
							<div v-for="tdo in listToShown" :key="tdo.id" class="each-approval-relate d-flex align-items-center my-1">
								<input v-model="listPickedApprovals" type="checkbox" :id="`each-approval-${tdo.id}`" :value="tdo.id" />
								<label
									:for="`each-approval-${tdo.id}`"
									class="each-approval-label pointer text-limit-w px-2"
									:class="listPickedApprovals.includes(tdo.id) ? 'active-label' : ''"
								>
									{{ tdo.id }}: {{ tdo.name }}
								</label>
							</div>
						</div>
					</div>
				</div>
				<div class="dialog-add__ft">
					<v-btn
						color="#005a88"
						class="btn-yes btn-normal-case popup__btn"
						:disabled="onAttachProcess"
						:loading="onAttachProcess"
						@click="onAttachApproval"
					>
						Lưu</v-btn
					>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { tStr } from 'validation_t/src';
	import api from '@/services';
	export default {
		props: {
			currentData: {
				type: Object,
			},
			getCurrentData: {
				type: Function,
			},
			setRefGetFollower: {
				type: Function,
			},
		},
		data() {
			return {
				currentId: '',
				loadingBtn: false,
				errMsg: '',
				dialogSTodo: false,
				onAttachProcess: false,
				listCurrentTodo: [],
				listCurrentChildren: [],
				listAvailableParent: [],
				listAvailableChild: [],
				listPickedApprovals: [],
				txtQuery: '',
				refQuery: 0,
				refScrollLoad: 0,
				parentCounter: 20,
				parentTotal: 20,
				childCounter: 20,
				childTotal: 20,
				currentRelateType: 0, // 0: parent, 1: child
			};
		},
		mounted() {
			const params = this.$route.params;
			this.currentId = params.id;
			this.getListParentAvailable();
			this.getListChildAvailable();
			if (this.currentData) {
				this.listCurrentTodo = this.currentData.tasks ? [...this.currentData.tasks] : [];
				this.listCurrentChildren = this.currentData.children ? [...this.currentData.children] : [];
			}
		},
		computed: {
			listAvailableTodoComputed() {
				return this.listAvailableTodo.filter(o => {
					const txtSrc = String(o.id + o.title).replaceAll(' ', '');
					return tStr.removeAscent(txtSrc).includes(tStr.removeAscent(String(this.txtQuery || '').replaceAll(' ', '')));
				});
			},
			parentComputed() {
				return this.currentData.parents;
			},
			listToShown() {
				return this.currentRelateType ? this.listAvailableChild : this.listAvailableParent;
			},
		},
		watch: {
			$route() {
				const params = this.$route.params;
				this.currentId = params.id;
			},
			txtQuery() {
				if (this.refQuery) {
					clearTimeout(this.refQuery);
				}
				this.refQuery = setTimeout(() => {
					if (this.currentRelateType) {
						// child query
						this.getListChildAvailable();
					} else {
						// parent query
						this.getListParentAvailable();
					}
				}, 600);
			},
			currentData: {
				deep: true,
				handler() {
					if (this.currentData) {
						this.listCurrentTodo = this.currentData.tasks ? [...this.currentData.tasks] : [];
						this.listCurrentChildren = this.currentData.children ? [...this.currentData.children] : [];
					}
				},
			},
			// listPickedApprovals: {
			// 	deep: true,
			// 	handler() {
			// 		this.$nextTick(() => {
			// 			if (!this.currentRelateType) {
			// 				if (this.listPickedApprovals && this.listPickedApprovals.length > 1) {
			// 					this.listPickedApprovals = [this.listPickedApprovals.pop()];
			// 				}
			// 			}
			// 		});
			// 	},
			// },
		},
		methods: {
			onCloseAddRelate() {
				this.currentRelateType = 0;
				this.dialogSTodo = false;
				this.listPickedApprovals = [];
				this.txtQuery = '';
			},
			showAddRelate(_num) {
				this.currentRelateType = _num;
				this.dialogSTodo = true;
			},
			handlerOpenDialogAddJob() {
				this.isVisible = true;
			},
			handlerDialogCancel() {
				this.isVisible = false;
			},
			formatDate(ds) {
				let ar1 = ds.split('-');
				return `${ar1[2]}-${ar1[1]}-${ar1[0]}`;
			},
			formatTick(ds) {
				return ds ? 1 : 0;
			},
			async getListParentAvailable() {
				const params = {
					size: this.parentCounter,
				};
				if (this.txtQuery) {
					params.search = this.txtQuery;
				}
				const res = await api.approval.getListParentAvailable(this.$route.params.id, params);
				if (!res) {
					return;
				}
				try {
					if (res.status > 199 && res.status < 399) {
						this.listAvailableParent = res.data.data.approvals;
						this.parentTotal = res.data.data.meta.pagination.total;
					} else {
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: res.data.message || res.data.data.error,
						});
					}
				} catch (error) {
					console.log(error);
				}
			},
			async getListChildAvailable() {
				const params = {
					size: this.childCounter,
				};
				if (this.txtQuery) {
					params.search = this.txtQuery;
				}
				const res = await api.approval.getListChildAvailable(this.$route.params.id, params);
				if (!res) {
					return;
				}
				try {
					if (res.status > 199 && res.status < 399) {
						this.listAvailableChild = res.data.data.approvals;
						this.childTotal = res.data.data.meta.pagination.total;
					} else {
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: res.data.message || res.data.data.error,
						});
					}
				} catch (error) {
					console.log(error);
				}
			},
			async onAttachApproval() {
				if (!this.listPickedApprovals || !this.listPickedApprovals.length) {
					return;
				}
				if (this.currentRelateType) {
					// add relate child
					const body = {
						child_ids: this.listPickedApprovals,
					};
					this.onAttachProcess = true;
					const res = await api.approval.addChildRelate(this.currentId, body);

					if (!res) {
						this.onAttachProcess = false;
						return;
					}
					try {
						if (res.status > 199 && res.status < 399) {
							this.getListChildAvailable();
							await this.getCurrentData();
							this.$store.commit('SET_SNACKBAR', {
								type: 'success',
								title: '',
								content: 'Gán đề xuất thành công',
							});
							this.onAttachProcess = false;
							this.onCloseAddRelate();
							this.setRefGetFollower();
						} else {
							this.onAttachProcess = false;
							this.$store.commit('SET_SNACKBAR', {
								type: 'error',
								title: '',
								content: res.data.message || res.data.data.error,
							});
						}
					} catch (error) {
						this.onAttachProcess = false;
						console.log(error);
					}
				} else {
					// add relate parent
					const body = {
						parent_ids: [...this.listPickedApprovals],
					};

					this.onAttachProcess = true;
					const res = await api.approval.addParentRelate(this.currentId, body);
					if (!res) {
						this.onAttachProcess = false;
						return;
					}
					try {
						if (res.status > 199 && res.status < 399) {
							this.getListParentAvailable();
							await this.getCurrentData();
							this.$store.commit('SET_SNACKBAR', {
								type: 'success',
								title: '',
								content: 'Gán công việc thành công',
							});
							this.onCloseAddRelate();
							this.onAttachProcess = false;
							this.setRefGetFollower();
						} else {
							this.onAttachProcess = false;
							this.$store.commit('SET_SNACKBAR', {
								type: 'error',
								title: '',
								content: res.data.message || res.data.data.error,
							});
						}
					} catch (error) {
						this.onAttachProcess = false;
						console.log(error);
					}
				}
			},
			async onDetachChild(_id) {
				if (this.onDetachProcess) {
					return;
				}
				const body = {
					child_id: _id,
				};
				this.onDetachProcess = true;
				const res = await api.approval.detachChild(this.currentId, body);

				if (!res) {
					return;
				}
				try {
					if (res.status > 199 && res.status < 399) {
						this.getListChildAvailable();
						await this.getCurrentData();
						this.$store.commit('SET_SNACKBAR', {
							type: 'success',
							title: '',
							content: 'Gỡ đề xuất thành công',
						});
						this.dialogSTodo = false;
						this.setRefGetFollower();
					} else {
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: res.data.message || res.data.data.error,
						});
					}
				} catch (error) {
					console.log(error);
				}
				this.$nextTick(() => {
					this.onDetachProcess = false;
				});
			},
			async onDetachParent(_id) {
				if (this.onDetachProcess) {
					return;
				}
				const body = {
					parent_id: _id,
				};
				this.onDetachProcess = true;
				const res = await api.approval.detachParent(this.currentId, body);

				if (!res) {
					return;
				}
				try {
					if (res.status > 199 && res.status < 399) {
						this.getListParentAvailable();
						await this.getCurrentData();
						this.$store.commit('SET_SNACKBAR', {
							type: 'success',
							title: '',
							content: 'Gỡ đề xuất thành công',
						});
						this.dialogSTodo = false;
						this.setRefGetFollower();
					} else {
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: res.data.message || res.data.data.error,
						});
					}
				} catch (error) {
					console.log(error);
				}
				this.$nextTick(() => {
					this.onDetachProcess = false;
				});
			},
			async handlerDialogSubmit(value, value2, value3, value4) {
				// let due_date = this.formatDate(value.due_date);
				let due_date = this.formatDate(value.due_date) + ' ' + value.due_dateTime + ':00';
				let important = this.formatTick(value.is_important);
				let arrayId = value2.map(o => o.id);
				let arrayFollowersId = value4.map(o => o.id);
				let arrayFile = value3.map(o => o);
				// console.log(value);
				// if (value) {
				// 	return;
				// }
				delete value.due_date;
				delete value.is_important;
				const formData = new FormData();
				arrayFile.forEach(element => {
					formData.append('attachments[]', element.blob);
				});
				arrayFollowersId.forEach(element => {
					formData.append('user_follow_ids[]', element);
				});
				arrayId.forEach(element => {
					formData.append('user_do_ids[]', element);
				});
				formData.append('due_date', due_date);
				formData.append('important', important);
				formData.append('title', value.title);
				formData.append('content', value.content);

				this.errMsg = '';
				this.loadingBtn = true;
				const res = await api.approval.addTask(this.currentId, formData);
				this.loadingBtn = false;
				if (!res) {
					return;
				}
				try {
					if (res.status > 199 && res.status < 399) {
						this.$store.commit('SET_SNACKBAR', {
							type: 'success',
							title: '',
							content: 'Tạo việc thành công',
						});
						this.$store.dispatch('tasks/getListTask');
						this.handlerDialogCancel();
						this.getCurrentData();
					} else {
						this.errMsg = res.data.message || res.data.data.error;
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: res.data.message || res.data.data.error,
						});
					}
				} catch (error) {
					console.log(error);
					this.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
					});
				}
			},
			formatDMY(ds) {
				if (!ds) {
					return '';
				}
				let ar1 = ds.split(' ');
				let ar2 = ar1[0].split('-');
				return `${ar2[2]}/${ar2[1]}/${ar2[0]}`;
			},
			nameUsersDo(users) {
				let name = '';
				users.forEach((o, idx) => {
					name += idx > 0 ? `, ${o.full_name}` : o.full_name;
				});
				return name;
			},
			redirectTodo(_id) {
				const currentLocationOrigin = window.location.origin;
				window.open(`${currentLocationOrigin}/de-xuat/chi-tiet/${_id}`);
			},
			getStatusClass(_sttCode) {
				switch (_sttCode) {
					case 1:
						return 'new';
					case 2:
						return 'sent';
					case 3:
						return 'done';
					case 4:
						return 'decline';
					default:
						return '';
				}
			},
			onScrollLoadmore(e) {
				if (e.target.scrollTop + e.target.clientHeight + 80 > e.target.scrollHeight) {
					if (this.refScrollLoad) {
						clearTimeout(this.refScrollLoad);
					}
					this.refScrollLoad = setTimeout(() => {
						clearTimeout(this.refScrollLoad);
						if (this.currentRelateType) {
							if (this.childCounter < this.childTotal) {
								this.childCounter += 15;
								this.$nextTick(() => {
									this.getListChildAvailable();
								});
							}
						} else {
							if (this.parentCounter < this.parentTotal) {
								this.parentCounter += 15;
								this.$nextTick(() => {
									this.getListParentAvailable();
								});
							}
						}
					}, 200);
				}
			},
		},
	};
</script>
<style lang="scss" scoped>
	.list-parent-approval,
	.list-child-approval {
		height: calc((100vh - 295px) / 2);
		overflow-y: scroll;
		&::-webkit-scrollbar {
			width: 4px;
			height: 6px;
			opacity: 0;
		}

		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
		}
		&:hover {
			&::-webkit-scrollbar-thumb {
				background: rgba(0, 0, 0, 0.15);
			}
		}
		.each-list-title {
			position: sticky;
			top: 0px;
			background-color: #fff;
			width: 100%;
			padding: 6px 12px;
			z-index: 2;
		}
		.each-current-approval {
			padding: 14px 16px;
			border-bottom: 1px solid #dfe5ea;
			position: relative;
			.detach-img {
				position: absolute;
				top: 20px;
				right: 16px;
				z-index: 2;
			}
			.todo-name {
				width: fit-content;
				text-align: left;
				color: #006a9b;
				font-weight: 500;
				text-decoration: underline;
			}
			.line-label {
				font-size: 14px;
				color: #223140;
				font-weight: 500;
				span {
					font-weight: 700;
				}
			}
			.todo-status {
				&-new {
					color: #f2f4f6;
				}
				&-sent {
					color: #006a9b;
				}
				&-done {
					color: #57ce8c;
				}
				&-decline {
					color: #b61f34;
				}
			}
		}
	}
</style>
<style lang="scss">
	.form-relate-container {
		.btn-yes {
			color: #ffffff !important;
			background: #005a88;
			font-size: 12px;
			height: 40px;
			border-radius: 8px;
			/* width: 98px; */
			text-transform: none;
		}
		.header-group-btn {
			border-bottom: 1px solid #dfe5ea;
		}
		.no-result-noti {
			color: #8194a5;
		}
	}
	.list-child-approval {
		border-top: 1px solid #dfe5ea;
	}
	.btn-normal-case {
		text-transform: none !important;
	}
	.btn-yes {
		color: #ffffff !important;
	}
	.dialog-add__ft {
		display: flex;
		justify-content: flex-end;
		padding: 12px 20px 12px 30px;
		margin: 0;
		background-color: #f2f4f6;
	}
	.field-attach-approval {
		.v-input__control {
			.v-input__slot {
				font-size: 14px !important;
				fieldset {
					color: rgb(227 239 223) !important;
				}
			}
		}
	}
	.list-approval-relate {
		height: 240px;
		overflow-y: scroll;
		padding: 12px;
		border: solid 1px #dee5eb;
		margin-bottom: 16px;
		&::-webkit-scrollbar {
			width: 4px;
			height: 6px;
			opacity: 0;
		}

		&::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
		}
		&:hover {
			&::-webkit-scrollbar-thumb {
				background: rgba(0, 0, 0, 0.15);
			}
		}
		.each-approval-relate {
		}
		.each-approval-label {
			font-size: 14px;
			color: #8194a5;
			&.active-label {
				font-weight: 500;
				color: #006a9b;
			}
		}
	}
</style>
