




































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import api from '@/services';
export default defineComponent({
	components: {},
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		currentId: {
			type: Number,
			default: 0,
		},
		loadingBtn: {
			type: Boolean,
			default: false,
		},
		handlerCancel: {
			type: Function,
		},
		handlerSubmit: {
			type: Function,
		},
		leftDoc: {
			type: Array,
			default() {
				return [];
			},
		},
		clickOnDoc: {
			type: Function,
		},
	},
	setup: (props, ctx) => {
		let formData = ref<Record<string, any>>({});
		const isActive = ref<boolean>(false);
		const changeFileUpload = (file: any) => {
			console.log(file);
		};
		const btnCancelClick = () => {
			isActive.value = false;
			ctx.emit('handlerCancel');
		};
		const btnSubmitClick = () => {
			if (!formData.value.reason) {
				isActive.value = true;
				return;
			}
			ctx.emit('handlerSubmit', formData.value);
			isActive.value = false;
			ctx.emit('handlerCancel');
		};
		return {
			btnCancelClick,
			btnSubmitClick,
			isActive,
			changeFileUpload,
			formData,
		};
	},
	methods: {},
	watch: {},
});
