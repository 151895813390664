












































import { defineComponent, ref } from "@vue/composition-api";
export default defineComponent({
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    innerContent: {
      type: Object,
      default() {
        return {};
      },
    },

    loadingBtn: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    handlerCancel: {
      type: Function,
    },
    handlerSign: {
      type: Function,
    },
    handlerSubmit: {
      type: Function,
    },
  },
  setup: (props: any, ctx: any) => {
    let formData = ref<Record<string, any>>({});
    let formData2 = ref<Record<string, any>[]>([]);
    const changeFileUpload = (file: any) => {
      console.log(file);
    };
    const btnCancelClick = () => {
      ctx.emit("handlerCancel");
    };
    const isActive = ref<boolean>(false);
    const btnSubmitClick = () => {
      if (!formData.value.bag_ids) {
        isActive.value = true;
        return;
      }
      ctx.emit("handlerSubmit", formData.value);
    };
    return {
      btnCancelClick,
      btnSubmitClick,
      changeFileUpload,
    };
  },
  methods: {
    onPrint() {
      const currentId = this.$route.params.id;
      window.open(`/tai-lieu/in/${this.innerContent.id}?c=${currentId}`);
    },
  },
  watch: {},
});
