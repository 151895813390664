























import Vue from "vue";
import {
 ref,
 defineComponent,
 reactive,
 watch,
 toRefs,
} from "@vue/composition-api";
import {
 TableFiltersInput,
 TableFiltersSelect,
 TableFiltersDateRange,
} from "@/components/Shared/index";
import { NormalFilterObject } from "@/InterfaceModel/Filter";
import { returnFilterObject } from "@/utils/TableFilters";
export default defineComponent({
 props: {
  tableData: {
   type: Array,
   default(){
     return [];
   },
  },
  tableLoading: {
   type: Boolean,
  },
  headers: {
   type: Array,
   default: [],
  },
  handleRemoveItem: {
   type: Function,
  },
 },
 components: { TableFiltersInput, TableFiltersSelect, TableFiltersDateRange },
 setup: (props, ctx) => {
  let selectedData = ref<Record<string, unknown>>({});

  const setSelectedData = (payload: Record<string, unknown>) => {
   selectedData.value = payload;
  };
  const deleteItem = (item: Record<string, string>) => {
   setSelectedData(item);
   ctx.emit("handleRemoveItem", selectedData.value);
  };
  //  watch(selectedData, currentValue => {
  //   // reactive when filter change here
  //  });
  //  watch(selectedDataDetail, currentValue => {
  //  });
  return {
   deleteItem,
  };
 },
 data() {
  return {
   arraySort: [],
  };
 },

 methods: {},
});
