
















































































































































































































































































































































































































































































































































































































































































































import { ref, defineComponent, computed, onMounted } from '@vue/composition-api';
import { useState } from 'vuex-composition-helpers/dist';
import DialogAddMemberToCate from '@/components/Form/DialogAddMemberToCate.vue';
import {
	// DraftTab,
	UploadFile,
	TableApprover,
} from '@/components/Layout/page-view/propose';
import { DialogAddNewMember } from '@/components/Layout/page-view/approval';
import { mapState } from 'vuex';
import DateRangeTableHeader from '@/components/Shared/DateRangeTableHeader.vue';
import useRouteQuery from '@/utils/uses/routerQuery/useRouteQuery';
import api from '@/services';
export default defineComponent({
	components: {
		//  DraftTab,
		UploadFile,
		TableApprover,
		DialogAddNewMember,
		DialogAddMemberToCate,
		DateRangeTableHeader,
		'ckeditor-nuxt': () => {
			return import('@blowstack/ckeditor-nuxt');
		},
	},
	props: {
		getCurrentData: {
			type: Function,
			default() {
				console.log('df getCurrentData');
			},
		},
		currentData: {
			type: Object,
		},
	},
	setup: (props: any, ctx: any) => {
		const { queryRoute, stringQueryRender, getQueryRoute } = useRouteQuery();
		const headers = [
			{
				text: 'Họ tên',
				align: 'start',
				value: 'full_name',
				sortable: false,
			},
			{
				text: 'Chức danh',
				align: 'start',
				value: 'roleTxt',
				sortable: false,
			},
			{
				text: 'Trạng thái',
				align: 'start',
				value: 'status',
				sortable: false,
			},
			{
				text: 'Ngày phê duyệt',
				align: 'start',
				value: 'status',
				sortable: false,
			},
			{
				align: 'start',
				value: 'action',
				sortable: false,
			},
		];
		let cate_id = ref<number>(0);
		let cateObj = ref<Record<string, any>>({});
		const isVisible = ref(false);
		const loadingBtn = ref(false);
		const setIsVisible = (value: boolean) => {
			isVisible.value = value;
		};
		const setCateId = (value: number) => {
			cate_id.value = value;
		};
		const setCateObj = (value: Record<string, any>) => {
			cateObj.value = value;
		};
		const { auth } = useState(['auth']);
		const content = '<p>Content of the editor.</p>';
		const token = auth.value.token;
		const editorConfig = {
			toolbar: {
				items: ['Paragraph', 'bold', 'italic', '|', 'undo', 'redo', 'numberedList', 'bulletedList', 'ImageUpload'],

				shouldNotGroupWhenFull: true,
			},
			exportPdf: {},
			pagination: {
				// A4
				pageWidth: '21cm',
				pageHeight: '29.7cm',

				pageMargins: {
					top: '20mm',
					bottom: '20mm',
					left: '12mm',
					right: '12mm',
				},
			},
			removePlugins: ['Title', 'CodeBlock', 'Code'],
			simpleUpload: {
				uploadUrl: process.env.BASE_URL + '/api/user/announcements/upload-image',
				headers: {
					Authorization: token,
				},
			},
		};
		let tab = ref(1);
		let approverList = ref<Record<string, any>>({ value: [] });
		let followerList: any[] = [];
		const name = ref<string>('');
		const description = ref<string>('');
		const fileClipboard = ref<Array<any>>([]);
		const messageErr = ref<string>('');
		let visibleAddMember = ref<boolean>(false);
		let listApprovalMember = ref<any[]>([]);
		// const fileClipboard = ref<Record<string,any>[]>([{a:1}])
		const changeFileUpload = (file: Array<any>) => {
			fileClipboard.value = fileClipboard.value.concat(file);
		};
		const clearFileUpload = () => {
			fileClipboard.value = [];
		};
		const setMessageErr = (payload: string) => {
			messageErr.value = payload;
		};
		const removeFileUpload = (id: number) => {
			fileClipboard.value.splice(
				fileClipboard.value.findIndex(o => o._id == id),
				1
			);
		};
		const removeItem = (items: any) => {
			console.log(items);
		};
		let participants = ref<Record<string, any>[]>([]);
		let companies = ref<Record<string, any>[]>([]);
		const addNewMembersToMetting = (member: Array<any>) => {
			member.forEach((o: Record<string, any>) => {
				let str = '';
				o.positions.forEach((k: any) => {
					str += k.name + '. ';
				});

				o.roleTxt = str;
				o.status = '-';
			});
			cateObj.value.approvers = member;
		};
		const addFollwers = () => {
			followerList.push(auth.value.user.user.id);
		};

		const setLoadingBtn = (payload: boolean) => {
			loadingBtn.value = payload;
		};
		const getDetailCate = async (fields: any, currentData: any) => {
			const res = await api.categories.getDetail(cate_id.value);
			if (!res) {
				ctx.root.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Không lấy được thông tin danh mục',
				});
				return;
			}
			try {
				if (res.status > 199 && res.status < 399) {
					const result = res.data.data.category;

					result.approvers = currentData.approvers.slice();
					result.approvers.forEach((o: Record<string, any>) => {
						let str = '';
						o.positions.forEach((k: any) => {
							str += k.name + '. ';
						});

						o.roleTxt = str;
						o.status = '-';
					});
					result.fields = fields.map((k: any) => {
						const obj = { ...k };
						if (k.type && k.type.id === 3) {
							obj.field_values_computed = k.dynamic_attributes.choices.slice();
							obj.field_values_single_check = k.values || '';
						}
						if (k.type && k.type.id === 4) {
							obj.field_values_computed = k.dynamic_attributes.choices.slice();
							if (k.values) {
								obj.field_values_multi_check = k.values.slice() || [];
							} else {
								obj.field_values_multi_check = [];
							}
						}
						if (k.type && k.type.id === 6) {
							obj.field_values = obj.values;
						}
						if (k.type && k.type.id === 7) {
							if (k.values) {
								obj.field_values_time = String(k.values).split(' ')[1];
								obj.field_values_date = String(k.values).split(' ')[0];
							}
						}
						if (k.type && k.type.id === 8) {
							obj.field_values = obj.values || ['', ''];
						}
						if (k.type && k.type.id === 9) {
							if (k.values && k.values.length) {
								const arrDate = k.values.map((j: string) => {
									return j ? j.split(' ')[0] : '';
								});
								const arrTime = k.values.map((j: string) => {
									return j ? j.split(' ')[1] : '';
								});
								obj.field_values_time = arrTime;
								obj.field_values_date = arrDate;
							} else {
								obj.field_values_time = ['', ''];
								obj.field_values_date = ['', ''];
							}
						}
						if (k.type && (k.type.id === 1 || k.type.id === 2)) {
							obj.field_values = k.values;
						}
						if (k.type && k.type.id === 5) {
							obj.field_values = k.values;
						}
						if (k.type && k.type.id === 11) {
							obj.field_values = k.values ? k.values.id : null;
						}
						if (k.type && k.type.id === 10) {
							obj.field_values_columns_head = k.dynamic_attributes.columns.slice();
							if (k.values && k.values.length) {
								obj.field_values_columns_body = k.values.map((o: any) => {
									return o.map((i: any) => {
										return {
											txt: i,
										};
									});
								});
							} else {
								obj.field_values_columns_body = [
									k.dynamic_attributes.columns.map((o: any) => {
										return {
											txt: '',
										};
									}),
								];
							}
						}
						if (k.type && k.type.id === 12) {
							obj.field_values_columns_head = k.dynamic_attributes.columns.map((o: Record<string, any>) => {
								return o.name;
							});
							if (k.values && k.values.length) {
								obj.field_values_columns_body = k.values.map((o: any) => {
									return o.map((i: any, fidx: number) => {
										return {
											txt: i,
											type: k.dynamic_attributes.columns[fidx] ? k.dynamic_attributes.columns[fidx].type : '',
										};
									});
								});
							} else {
								obj.field_values_columns_body = [
									k.dynamic_attributes.columns.map((o: any) => {
										return {
											txt: '',
											type: o.type,
										};
									}),
								];
							}
						}
						return obj;
					});
					setCateObj({ ...result });

					// cateObj = res.data.data.category;
				} else {
					ctx.root.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: res.data.message || res.data.data.error,
					});
				}
			} catch (error) {
				console.log(error);
				ctx.root.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Không lấy được thông tin danh mục. Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
			}
		};
		const setUpCurrentData = (data: Record<string, any>) => {
			setCateId(data.category.id);
			name.value = data.name;
			description.value = data.description;
			const fields = data.fields.slice();
			getDetailCate(fields, data);
		};
		const handlerSendPropose = async () => {
			messageErr.value = '';
			setLoadingBtn(true);
			const res = await api.propose.send(cate_id.value);
			setLoadingBtn(false);
			if (!res) {
				ctx.root.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
				return;
			}
			try {
				if (res.status > 199 && res.status < 399) {
					let _data = res.data.data.sender;
					ctx.root.$store.commit('SET_SNACKBAR', {
						type: 'success',
						title: '',
						content: 'Chỉnh sửa đề xuất thành công',
					});
				} else {
					messageErr.value = res.data.message || res.data.data.error;
					ctx.root.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: res.data.message || res.data.data.error,
					});
				}
			} catch (error) {
				ctx.root.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
			}
		};

		const toDayFormated = `${String(new Date().getDate()).padStart(2, '0')}-${String(
			new Date().getMonth() + 1
		).padStart(2, '0')}-${new Date().getFullYear()}`;
		const handlerDeleteApprover = (id: Record<string, any>) => {
			let obj = { ...cateObj.value };
			obj.approvers = obj.approvers.filter((o: any) => {
				return o.id !== id.id;
			});
			setCateObj(obj);
		};

		const setVisibleAddMember = (payload: boolean) => {
			visibleAddMember.value = payload;
		};
		const setListApprovalMember = (payload: any[]) => {
			listApprovalMember.value = payload;
		};
		return {
			toDayFormated,
			auth,
			name,
			description,
			visibleAddMember,
			listApprovalMember,
			headers,
			tab,
			approverList,
			followerList,
			isVisible,
			loadingBtn,
			content,
			participants,
			fileClipboard,
			cateObj,
			messageErr,
			companies,
			editorConfig,
			changeFileUpload,
			removeFileUpload,
			setIsVisible,
			removeItem,
			addNewMembersToMetting,
			cate_id,
			getDetailCate,
			handlerSendPropose,
			handlerDeleteApprover,
			addFollwers,
			setLoadingBtn,
			setMessageErr,
			setUpCurrentData,
			setVisibleAddMember,
			setListApprovalMember,
			clearFileUpload,
		};
	},
	beforeDestroy() {
		this.$store.dispatch('SET_ADDLIBACTION', () => {
			console.log('changedroute');
		});
	},
	data() {
		return {
			listAddedSigner: [
				{
					title: '',
				},
			],
			selectedDetail: {},
			defaultTitles: [] as Record<string, any>[],
			listUnsave: [] as Record<string, any>[],
			selectedDefaultTitles: {} as Record<string, any>,
			selectedNewTitles: {} as Record<string, any>,
			dialogInputTitle: false as boolean,
			approverLoading: false,
			refKey: 1,
		};
	},
	watch: {
		currentData: {
			deep: true,
			handler() {
				console.log(this.currentData);
				if (this.currentData && this.currentData.id && this.cateObj && !this.cateObj.id) {
					this.setUpCurrentData(this.currentData);
				}
				if (!this.defaultTitles.length) {
					this.defaultTitles = this.currentData.approver_titles.slice();
				}
			},
		},
		// cateObj: {
		// 	deep: true,
		// 	handler() {
		// 		if (!this.defaultTitles.length) {
		// 			this.defaultTitles = this.cateObj.default_titles.slice();
		// 		}
		// 	},
		// },
	},
	mounted() {
		if (!this.defaultTitles.length) {
			console.log(this.currentData);
			// this.defaultTitles = this.currentData.value.default_titles.slice();
		}
	},
	methods: {
		async removeFileUploadApi(attachment_id: string | number) {
			const body = {
				attachment_id,
			};
			const res = await api.approval.rmFile(this.currentDataComputed.id, body);
			if (!res) {
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Xóa file thất bại',
				});
				return;
			}
			try {
				if (res.status > 199 && res.status < 399) {
					this.$store.commit('SET_SNACKBAR', {
						type: 'success',
						title: '',
						content: 'Xóa file thành công',
					});
					this.getCurrentData();
				} else {
					this.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: res.data.message || res.data.data.error,
					});
				}
				this.getCurrentData();
			} catch (error) {
				console.log(error);
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
				});
			}
		},
		handlerDialogCancel() {
			this.setIsVisible(false);
		},
		handleAddRow(field: any) {
			if (field.field_values_columns_body && field.field_values_columns_body) {
				const newArr = field.field_values_columns_body[field.field_values_columns_body.length - 1].map((o: any) => {
					return { txt: '' };
				});
				field.field_values_columns_body.push(newArr);
			}
		},
		handleAddCurrencyRow(field: any) {
			if (field.field_values_columns_body) {
				const newArr = field.field_values_columns_body[field.field_values_columns_body.length - 1].map((o: any) => {
					return { txt: '', type: o.type };
				});
				field.field_values_columns_body.push(newArr);
			}
		},
		handleRemoveRow(field: any, idx: any) {
			if (field.field_values_columns_body) {
				field.field_values_columns_body.splice(idx, 1);
			}
		},
		handlerDialogSubmit(value: any) {
			console.log(value);
		},
		addMember() {
			this.setIsVisible(true);
		},
		addOptToField(arr: Array<any>) {
			arr.push({ txt: '' });
		},
		removeOptToField(arr: Array<any>, idx: number) {
			arr.splice(idx, 1);
		},
		pickedSingleDate(_val: any, field: any) {
			if (_val.value && _val.value.startDate) {
				const arr = _val.value.startDate.split('-');
				field.field_values = `${arr[0]}/${arr[1]}/${arr[2]}`;
				field.values = `${arr[0]}/${arr[1]}/${arr[2]}`;
				return;
			}
			field.field_values = '';
			field.values = '';
		},
		pickedSingleDateFrom9(_val: any, field: any) {
			if (_val.value && _val.value.startDate) {
				const arr = _val.value.startDate.split('-');
				field.field_values_date[0] = `${arr[0]}/${arr[1]}/${arr[2]}`;
				field.values[0] = `${arr[0]}/${arr[1]}/${arr[2]}`;
				return;
			}
			field.field_values_date[0] = '';
			field.values[0] = '';
		},
		pickedSingleDateFrom(_val: any, field: any) {
			if (_val.value && _val.value.startDate) {
				const arr = _val.value.startDate.split('-');
				field.field_values[0] = `${arr[0]}/${arr[1]}/${arr[2]}`;
				field.values[0] = `${arr[0]}/${arr[1]}/${arr[2]}`;
				return;
			}
			field.field_values[0] = '';
			field.values[0] = '';
		},
		pickedSingleDateTo(_val: any, field: any) {
			if (_val.value && _val.value.startDate) {
				const arr = _val.value.startDate.split('-');
				field.field_values[1] = `${arr[0]}/${arr[1]}/${arr[2]}`;
				field.values[1] = `${arr[0]}/${arr[1]}/${arr[2]}`;
				return;
			}
			field.field_values[1] = '';
		},
		pickedSingleDateTo9(_val: any, field: any) {
			if (_val.value && _val.value.startDate) {
				const arr = _val.value.startDate.split('-');
				field.field_values_date[1] = `${arr[0]}/${arr[1]}/${arr[2]}`;
				return;
			}
			field.field_values_date[1] = '';
		},
		pickedSingleDateWithTime(_val: any, field: any) {
			if (_val.value && _val.value.startDate) {
				const arr = _val.value.startDate.split('-');
				field.field_values_date = `${arr[0]}/${arr[1]}/${arr[2]}`;
				return;
			}
			field.field_values_date = '';
		},
		handlerCancelPropose() {
			this.$router.push('/');
		},
		validSubmit(fields: Array<any>) {
			return new Promise(resolve => {
				if (!this.name) {
					resolve('Không được để trống nội dung phê duyệt.');
					return;
				}
				const rsFields = fields.every((o: any) => {
					// if (o.required && (!o.field_values || !o.field_values.length)) {
					//  return false;
					// }
					return true;
				});
				if (!rsFields) {
					resolve('Bạn chưa điền đầy đủ thông tin các trường yêu cầu.');
					return;
				}
				resolve('');
			});
		},
		onSubmitAllFiles() {
			return new Promise(resolve => {
				let tb: any[] = [];
				const fClipBoard = this.fileClipboard.slice();
				this.clearFileUpload();
				fClipBoard.forEach(async (o: any) => {
					tb.push(
						new Promise(res => {
							const formData = new FormData();
							formData.append(`attachments[]`, o.blob);
							(async () => {
								const rs = await api.approval.addFile(this.currentData?.id, formData);
								res(rs);
							})();
						})
					);
				});
				Promise.all(tb).then(r => {
					resolve('done');
				});
			});
		},
		onSubmitAllUser() {
			return new Promise(resolve => {
				let tb: any[] = [];
				const arr = this.defaultTitles.filter((o: any) => {
					return o.title && o.user;
				});
				arr.forEach(async (o: any, idx: number) => {
					console.log(o);
					tb.push(
						new Promise((res, rej) => {
							const body = {
								user_id: o.user.id,
							};
							(async () => {
								const rs = await api.approval.addUser(this.currentData?.id, o.id, body);
								if (!rs) {
									this.$store.commit('SET_SNACKBAR', {
										type: 'error',
										title: '',
										content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
									});
									rej('Server failed');
								}
								if (rs.status > 199 && rs.status < 399) {
									res(rs);
								} else {
									//  this.setMessageErr( res.data.message || res.data.data.error );
									this.$store.commit('SET_SNACKBAR', {
										type: 'error',
										title: '',
										content: 'Thêm/sửa người phê duyệt thất bại: ' + rs.data.message || rs.data.data.error,
									});
									rej(rs.data.message || rs.data.data.error);
								}
							})();
						})
					);
				});
				Promise.all(tb)
					.then(
						r => {
							resolve('done');
						},
						err => {
							this.setLoadingBtn(false);
							console.log(err);
						}
					)
					.catch(err => this.setLoadingBtn(false));
			});
		},
		async handlerUpdatePropose() {
			this.setMessageErr('');
			this.addFollwers();
			const fields = this.cateObj.fields.map((o: any) => {
				let obj = {
					...o,
				};

				if (o.type && o.type.id === 4) {
					obj.field_values = obj.field_values_multi_check.slice();
					obj.dataType = 'multi_check';
				} else if (o.type && o.type.id === 3) {
					obj.field_values = obj.field_values_single_check;
					obj.dataType = 'single_check';
				} else if (o.type && o.type.id === 7) {
					if (o.field_values_date && o.field_values_time) {
						obj.field_values = `${o.field_values_date} ${o.field_values_time}`;
					} else {
						obj.field_values = `${o.field_values_date || ''} ${o.field_values_time || ''}`;
					}
				} else if (o.type && o.type.id === 10) {
					const firstRow = o.field_values_columns_body[0].map((o: Record<string, any>) => o.txt);
					if (!firstRow.join('')) {
						obj.field_values = null;
					} else {
						obj.field_values = o.field_values_columns_body.map((i: any) => {
							return i.map((j: any) => {
								return j.txt;
							});
						});
					}

					obj.dataType = 'table';
				} else if (o.type && (o.type.id === 5 || o.type.id === 11)) {
					obj.field_values = o.field_values;
					obj.dataType = 'single_date_only';
				} else if (o.type && (o.type.id === 1 || o.type.id === 2 || o.type.id === 6)) {
					obj.field_values = o.field_values;
				} else if (o.type && o.type.id === 8) {
					obj.dataType = 'multi_date';
				} else if (o.type && o.type.id === 12) {
					obj.field_values = o.field_values_columns_body.map((i: any) => {
						return i.map((j: any) => {
							return j.txt;
						});
					});
					obj.dataType = 'table_currency';
				} else if (o.type && o.type.id === 9) {
					let arr = ['', ''];
					arr.forEach((j, idx) => {
						j = `${o.field_values_date[idx]} ${o.field_values_time[idx]}`;
					});
					if (!o.field_values_date.join('') || !o.field_values_time.join('')) {
						obj.field_values = ['', ''];
					} else {
						obj.field_values = arr.map((j, idx) => {
							return `${o.field_values_date[idx]} ${o.field_values_time[idx]}`;
						});
					}
					obj.dataType = 'multi_date';
				} else {
					obj.field_values = obj.values;
				}
				return obj;
			});
			const inValid = await this.validSubmit(fields);
			if (!inValid) {
				this.setLoadingBtn(true);
				const xp = fields.map((o: Record<string, unknown>) => {
					const arr: any[] = [o.name, { field_values: o.field_values, dataType: o.dataType }];
					return arr;
				});
				const params = {
					name: this.currentData.name,
					description: this.description,
					category_id: this.cateObj.id,
					field_values: Object.fromEntries(xp),
					// approver_ids: this.cateObj.approvers.length ? this.cateObj.approvers.map((o: any) => o.id) : [],
					// follower_ids: [this.auth.user.user.id],
				};
				// w8 submit all file here
				await this.onSubmitAllFiles();
				const formData = new FormData();

				// w8 update all user
				await this.onSubmitAllUser();
				formData.append('name', params.name);
				formData.append('description', params.description);
				formData.append('category_id', params.category_id);
				// const listKeyFieldValues = Object.keys(params.field_values);
				const listKeyFieldValues = xp.map((o: any) => {
					return o[0];
				});
				listKeyFieldValues.forEach((o: any, idx: number) => {
					const onComputedVal = xp[idx][1];
					if (onComputedVal.dataType && onComputedVal.dataType === 'table') {
						if (!onComputedVal.field_values) {
							return;
						} else {
							onComputedVal.field_values.forEach((j: any, idxj: number) => {
								j.forEach((k: any, idxk: number) => {
									formData.append(`field_values[${o}][${idxj}][${idxk}]`, k);
								});
							});
							return;
						}
					}
					if (onComputedVal.dataType && onComputedVal.dataType === 'table_currency') {
						if (!onComputedVal.field_values) {
							return;
						} else {
							onComputedVal.field_values.forEach((j: any, idxj: number) => {
								j.forEach((k: any, idxk: number) => {
									formData.append(`field_values[${o}][${idxj}][${idxk}]`, k);
								});
							});
							return;
						}
					}
					if (onComputedVal.dataType && onComputedVal.dataType === 'multi_date') {
						onComputedVal.field_values.forEach((j: any, idxj: number) => {
							formData.append(`field_values[${o}][${idxj}]`, j);
						});
						return;
					}
					if (onComputedVal.dataType && onComputedVal.dataType === 'multi_check') {
						onComputedVal.field_values.forEach((j: any, idx: any) => {
							formData.append(`field_values[${o}][${idx}]`, j);
						});
						return;
					}
					if (onComputedVal.dataType && onComputedVal.dataType === 'single_check') {
						formData.append(`field_values[${o}]`, onComputedVal.field_values);
						return;
					}
					if (onComputedVal.dataType && onComputedVal.dataType === 'single_date_only') {
						formData.append(`field_values[${o}]`, onComputedVal.field_values || '');
						return;
					}
					if (!onComputedVal.dataType) {
						formData.append(`field_values[${o}]`, onComputedVal.field_values || '');
						return;
					}
				});
				this.setMessageErr('');
				const res = await api.propose.update(this.currentData?.id, formData);
				this.setLoadingBtn(false);
				if (!res) {
					this.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
					});
					return;
				}
				try {
					if (res.status > 199 && res.status < 399) {
						this.$store.commit('SET_SNACKBAR', {
							type: 'success',
							title: '',
							content: 'Chỉnh sửa đề xuất thành công',
						});
						this.clearFileUpload();
						this.$store.dispatch('getStatistical');
						this.getCurrentData();
						this.$forceUpdate();
					} else {
						//  this.setMessageErr( res.data.message || res.data.data.error );
						this.$store.commit('SET_SNACKBAR', {
							type: 'error',
							title: '',
							content: res.data.message || res.data.data.error,
						});
					}
				} catch (error) {
					this.$store.commit('SET_SNACKBAR', {
						type: 'error',
						title: '',
						content: 'Lỗi hệ thống, liên hệ IT để được hỗ trợ.',
					});
				}
			} else {
				// this.setMessageErr(`${inValid}`);
				this.$store.commit('SET_SNACKBAR', {
					type: 'error',
					title: '',
					content: `${inValid}`,
				});
			}
		},
		onChangeUser(data: Record<string, any>) {
			this.setVisibleAddMember(true);
			this.selectedDetail = { ...data };
			this.setListApprovalMember(data.user ? [data.user] : []);
		},
		onChangeNewUser(data: Record<string, any>, idx: number) {
			this.setVisibleAddMember(true);
			this.selectedDetail = { ...data, newIdx: idx };
			this.setListApprovalMember(data.user ? [data.user] : []);
		},
		handleAddRowPpl() {
			this.listAddedSigner.push({
				title: '',
			});
		},
		handleRemoveRowPpl(idx: number) {
			this.listAddedSigner.splice(idx, 1);
		},
		handleOpenAddMember() {
			this.setVisibleAddMember(true);
		},
		handleCloseAddMember() {
			this.setVisibleAddMember(false);
		},
		selectToChangeTitle(each: Record<string, any>, idx: number | string) {
			this.selectedDefaultTitles = {
				idx,
				each: { ...each },
			};
			this.dialogInputTitle = true;
		},
		selectToChangeNewTitle(each: Record<string, any>, idx: number | string) {
			this.selectedNewTitles = {
				idx,
				each: { ...each },
			};
			this.dialogInputTitle = true;
		},
		cancelToChangeTitle() {
			this.selectedDefaultTitles = {};
			this.dialogInputTitle = false;
		},
		onChangeDefaultTitle() {
			if (this.selectedDefaultTitles.each) {
				const arr = this.defaultTitles.slice();
				arr[this.selectedDefaultTitles.idx] = this.selectedDefaultTitles.each;
				this.defaultTitles = arr.slice();
				this.listUnsave.push(this.selectedDefaultTitles.each);
				this.$nextTick(() => {
					this.selectedDefaultTitles = {};
					this.selectedNewTitles = {};
					this.dialogInputTitle = false;
				});
			}
			if (this.selectedNewTitles.each) {
				const arr = this.listAddedSigner.slice();
				arr[this.selectedNewTitles.idx] = this.selectedNewTitles.each;
				this.listAddedSigner = [...arr];
				this.$nextTick(() => {
					this.selectedNewTitles = {};
					this.selectedDefaultTitles = {};
					this.dialogInputTitle = false;
				});
			}
		},
		onEnterTitle(_val: string) {
			if (_val.trim()) {
				this.onChangeDefaultTitle();
			}
		},
		changeApprovalMember(_val: any[]) {
			this.handleCloseAddMember();
			const obj: any = { ...this.selectedDetail };
			if (!obj.user) {
				obj.user = _val[0];
				if (obj.newIdx || obj.newIdx === 0) {
					const arr = [...this.listAddedSigner];
					arr[obj.newIdx] = obj;
					this.listAddedSigner = [...arr];
				} else {
					const idx = this.defaultTitles.findIndex((o: any) => o.id === obj.id);
					if (idx !== -1) {
						this.defaultTitles.splice(idx, 1, obj);
					}
				}
				return;
			}
			if (_val && _val[0].id === obj.user.id) {
				return;
			}
			obj.user = _val[0];
			const idx = this.defaultTitles.findIndex((o: any) => o.id === obj.id);

			if (idx !== -1) {
				this.defaultTitles.splice(idx, 1, obj);
			}
			if (obj.newIdx || obj.newIdx === 0) {
				const arr = [...this.listAddedSigner];
				arr[obj.newIdx] = obj;
				this.listAddedSigner = [...arr];
			}
		},
	},
	computed: {
		...mapState({
			user: (state: any) => state.auth.user.user,
			cards: (states: any) => states.categories.listCategories,
			listUsers: (state: any) => state.users.listUsers,
			cate() {
				return this.cards.find((o: any) => o.id == this.cate_id) || {};
			},
			listUsersComputed() {
				const arr = this.listUsers.slice();

				arr.forEach((o: any) => {
					o.fieldPickTxt = `${o.full_name} - ${o.asgl_id}`;
				});
				return arr;
			},
		}),
		currentDataComputed() {
			const obj: any = { ...this.currentData };
			if (obj.approvers) {
				obj.approvers.forEach((o: any) => {
					let str = '';
					o.positions.forEach((k: any) => {
						str += k.name + '. ';
					});

					o.roleTxt = str;
					o.status = '-';
				});
			}

			return { ...obj };
		},
		memberUsernames() {
			let _usernames: any[] = [];
			// for (let p of this.participantsTemp) {
			//   _usernames.push(p.username);
			// }
			return _usernames;
		},
	},
});
